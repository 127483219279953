import { Navigate } from "react-router-dom";

// Public Routes
import Landing from "./pages/landing";
import NotFound from "./pages/404";
import Home from "./pages/home";
import About from "./pages/about";
import Archives from "./pages/archives";
import Works from "./pages/works";
import {
  NeqtoWebsite,
  SafingWebsite,
  SamsungWebsite,
  SunlifeWebsite,
  CIBCWebsite
} from "./pages/works/websites";
import {
  NeqtoApp,
  SafingApp,
  PrimeApp
} from "./pages/works/apps";

export const publicRoutes = [
  {
    path: "*",
    element: <Navigate to={{ pathname: "/" }} replace={true} />,
  },
  {
    path: "/",
    element: <Landing />
  },
  {
    path: "/404",
    element: <NotFound />
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/archives",
    element: <Archives />,
  },
  {
    path: "/works",
    element: <Works />,
  },
  {
    path: "/works/websites",
    children: [
      {
        path: "neqto-website",
        element: <NeqtoWebsite />,
      },
      {
        path: "safing-website",
        element: <SafingWebsite />,
      },
      {
        path: "samsung-website",
        element: <SamsungWebsite />,
      },
      {
        path: "sunlife-website",
        element: <SunlifeWebsite />,
      },
      {
        path: "cibc-website",
        element: <CIBCWebsite />,
      },
    ],
  },
  {
    path: "/works/web-apps",
    children: [
      {
        path: "neqto-app",
        element: <NeqtoApp />,
      },
      {
        path: "safing-app",
        element: <SafingApp />,
      },
      {
        path: "prime-app",
        element: <PrimeApp />,
      },
    ],
  },
];
