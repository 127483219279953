import React from "react";

import { Layout } from "~/components/layouts";
import { Breadcrumbs, ProjectHeader, ProjectTile, SkillWrapper, Skills, Overview, Tag, Text, ScrollTopBtn } from "~/components/elements";

import SamsungHero from "~/assets/images/works/websites/samsung/samsung-hero.png";
import SamsungBanners from "~/assets/images/works/websites/samsung/samsung-banners.png";
import SamsungSurvey from "~/assets/images/works/websites/samsung/samsung-survey.png";

export default function SamsungWebsite() {
  return (
    <Layout>
      <Breadcrumbs
        works
        activeName="Websites"
      />

      <ProjectHeader
        title="Samsung Website"
        date="2018 - 2019"
        previousLink="/works/websites/safing-website"
        nextLink="/works/websites/sunlife-website"
      />

      <div className="project-content">
        <ProjectTile image={SamsungHero} top>
          <SkillWrapper>
            <Skills title="Design">
              <Tag label="Adobe Illustrator" />
              <Tag label="Adobe Animate CC" />
            </Skills>

            <Skills title="Development">
              <Tag label="HTML5" />
              <Tag label="CSS" />
            </Skills>
          </SkillWrapper>
          <Overview title="Illustration Animation for HTML5 Canvas">
            <p className="paragraph">
              A design was provided by an outsourced company along with custom illustrations. To increase user captivation, I was in charge of animating the illustrations using Adobe Animate CC, which was later exported and inserted with HTML5 Canvas.
            </p>
          </Overview>
        </ProjectTile>

        <div className="bg-blue">
          <ProjectTile image={SamsungBanners}>
            <Text title="Landing page Development">
              <p className="paragraph">
                My responsibilities included converting the provided landing page design into code. The challenge was cleanly styling the page with the various hover effects, animating imagery and interactive card flips. Responsiveness for the page was an important factor as well.
              </p>
            </Text>
          </ProjectTile>
        </div>

        <ProjectTile image={SamsungSurvey}>
          <Text title="Survey Development">
            <p className="paragraph">
              The hero banner of the landing page is a survey with animated imagery and stylistic form elements. I was responsible for completing the styling of the visual elements, ensuring the end result matched the design, with creative liberty on the animation of the illustrations.
            </p>
          </Text>
        </ProjectTile>

        <div className="divider"></div>
        <div className="project-link-wrapper">
          <a className="text-link" href="https://pages.samsung.com/ca/led/English/" target="_blank">
            See the Samsung Website
          </a>
        </div>
        <div className="divider"></div>
      </div>

      <ScrollTopBtn />
    </Layout>
  );
}
