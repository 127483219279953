import React from "react";
import { Link } from "react-router-dom";

import { Gradient, Layout } from "~/components/layouts";

export default function NotFound() {
  return (
    <Gradient>
      <Layout>
        <div className="not-found">
          <div className="container text-center mt-5 mb-5">
            <h1>404</h1>
            <div className="divider"></div>
            <p className="mt-5 mb-2">Sorry, we could not find the page you requested.</p>
            <div>
              <Link to={"/"} className="text-link">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </Gradient>
  );
}
