import React from "react";

import Img from "react-cool-img";

import { Layout } from "~/components/layouts";
import { Breadcrumbs } from "~/components/elements";

import Shoes from "~/assets/images/archives/shoes.jpg";
import Blackbarrett from "~/assets/images/archives/blackbarrett.jpg";
import Blackbarrett2 from "~/assets/images/archives/blackbarrett2.jpg";
import Victoria from "~/assets/images/archives/victoria.jpg";
import Dephy from "~/assets/images/archives/dephy.jpg";

import FourFaces from "~/assets/images/archives/four-faces.jpg";
import ShoeDrawn from "~/assets/images/archives/shoe-drawn.jpg";
import Pocket from "~/assets/images/archives/pocket.jpg";
import Pyro from "~/assets/images/archives/pyro.jpg";
import Twisted from "~/assets/images/archives/twisted.jpg";
import Butterfly from "~/assets/images/archives/butterfly.jpg";


export default function Archives() {
  return (
      <Layout>
        <Breadcrumbs
          activeName="Archives"
        />
        <h1 className="archives">Archives</h1>
        <div className="container text-center archives-content">
          <p className="paragraph mb-5">
            Below is a collection of art, graphics and photography I have created in the past for side projects and hobbies.
          </p>

          <p className="subheading">Photography</p>
          <div className="archives-image-wrapper">
            <Img src={Shoes} debounce="0" />
            <div className="blackbarrett">
              <Img src={Blackbarrett} debounce="0" />
              <Img src={Blackbarrett2} debounce="0" />
            </div>
            <Img src={Victoria} debounce="0" />
            <Img className="dephy" src={Dephy} debounce="0" />
          </div>

          <div className="divider mt-5"></div>

          <p className="subheading mt-5">Traditional Art</p>
          <div className="archives-image-wrapper">
            <Img className="large" src={FourFaces} debounce="0" />
            <Img className="drawn-shoe" src={ShoeDrawn} debounce="0" />
            <Img className="large" src={Pocket} debounce="0" />
            <Img className="pyro" src={Pyro} debounce="0" />
            <Img className="large" src={Twisted} debounce="0" />
            <Img className="butterfly" src={Butterfly} debounce="0" />
          </div>
        </div>
      </Layout>
  );
}
