import React from "react";

import Img from "react-cool-img";

import { Layout } from "~/components/layouts";
import { Breadcrumbs, ProjectHeader, ProjectTile, SkillWrapper, Skills, Overview, Tag, Text, ScrollTopBtn } from "~/components/elements";

import ConvertArrow from "~/assets/images/common/convert.svg";

import NeqtoHero from "~/assets/images/works/websites/neqto/neqto-hero.png";
import NeqtoTemplate from "~/assets/images/works/websites/neqto/neqto-template.png";
import NeqtoForms from "~/assets/images/works/websites/neqto/neqto-forms.png";
import NeqtoBlog from "~/assets/images/works/websites/neqto/neqto-blog.png";
import NeqtoPages from "~/assets/images/works/websites/neqto/neqto-pages.png";
import NeqtoOther from "~/assets/images/works/websites/neqto/neqto-other.png";


export default function NeqtoWebsite() {
  return (
    <Layout>
      <Breadcrumbs
        works
        activeName="Websites"
      />

      <ProjectHeader
        title="NEQTO Website"
        date="2019 - Present"
        noPrevious
        nextLink="/works/websites/safing-website"
      />

      <div className="project-content">
        <ProjectTile image={NeqtoHero} top>
          <SkillWrapper>
            <Skills title="Design">
              <Tag label="Adobe Illustrator" />
              <Tag label="Adobe Photoshop" />
              <Tag label="Adobe After Effects" />
              <Tag label="Adobe Premiere Pro" />
              <Tag label="Figma" />
            </Skills>

            <Skills title="Development">
              <Tag label="HTML5" />
              <Tag label="CSS" />
              <Tag label="JS / JQuery" />
              <Tag label="AWS S3" />
              <Tag label="AWS Cloudfront" />
              <Tag label="AWS Lambda" />
              <Tag label="Google Analytics" />
            </Skills>
          </SkillWrapper>
          <Overview title="Design + Development">
            <p className="paragraph">
              I was part of a two person team in redesigning the NEQTO website to better represent the brand and improve the visuals as well as the user interaction. After the design phase, I implemented most of the new pages in static HTML and CSS, ensuring responsiveness and meeting W3C accessibility standards.
            </p>
            <p className="paragraph">
              Continuous maintenance of the website was required, including design and development of new service pages, as well as release notes and news updates.
            </p>
          </Overview>
        </ProjectTile>

        <div className="bg-blue">
          <ProjectTile image={NeqtoTemplate}>
            <Text title="Template Engine Conversion">
              <p className="paragraph">
                Lead the project on template engine conversion by creating a more efficient and convenient process for developing the NEQTO website, from serving just static HTML, CSS and asset files, to using a template engine. From researching the suitable template engine, to converting the website, the process of developing new pages as well as making revisions was streamlined.
              </p>
              <p className="paragraph">
                Common files like the navigation were extracted as partial files. Macro files were created to use the same template for multilanguage.
              </p>
              <p className="paragraph convert">
                Static HTML, CSS, Asset Files
                <Img className="convert-arrow" src={ConvertArrow} />
                staticjinja Template Engine
              </p>
            </Text>
          </ProjectTile>
        </div>

        <ProjectTile image={NeqtoForms}>
          <Text title="Forms">
            <p className="paragraph">
              New pages requiring form data were designed by the team and developed by me. Validation for the forms had to be set up, as well as error handling with the API responses. The AWS Lambda function also had to be modified for spam check as well as for form downloads.
            </p>
          </Text>
        </ProjectTile>

        <div className="bg-blue">
          <ProjectTile image={NeqtoBlog}>
            <Text title="Blog Templating Conversion">
              <p className="paragraph">
                After converting the site to use a template engine, I wanted to take it a step further and make writing blogs for the website easier as well. It was difficult to find a blog templating engine that works well with the existing template engine, but eventually, I discovered Pelican, a blog templating engine also using Jinja2.
              </p>
              <p className="paragraph">
                I set up the blog template engine, automating category generation, related posts and article generation along with preset styling. Highlights.js was also implemented to make code blocks in the blogs easier to read. All that is needed to generate a new blog is a metadata file and a markdown file containing the article content.
              </p>
              <p className="paragraph">
                With the blog templating engine implemented, a developer is no longer needed to manually insert the blog content into the website using HTML and CSS.
              </p>
              <p className="paragraph convert">
                Manually writing blogs in HTML & CSS
                <Img className="convert-arrow" src={ConvertArrow} />
                Pelican Blog Template Engine
              </p>
            </Text>
          </ProjectTile>
        </div>

        <ProjectTile image={NeqtoPages}>
          <Text title="Webpages">
            <p className="paragraph">
              Some webpages required a little more dynamic functionality, such as filter implementation and dynamic file selection. For these pages, a little more Javascript and Jquery implementation was necessary.
            </p>
          </Text>
        </ProjectTile>

        <div className="bg-blue">
          <ProjectTile image={NeqtoOther}>
            <Text title="Other Responsibilities">
              <p className="paragraph">
                <strong>Google Analytics + SEO</strong>
                <br />
                Set up analytics filters to block internal IP Address traffic from being detected. Also set up filters to block out spam traffic from bots. Converted the website to reflect a 90+ score on Lighthouse.
              </p>
              <p className="paragraph">
                <strong>Video Editing</strong>
                <br />
                After Effects and Premiere Pro were used to create marketing and promotional videos for the NEQTO product. Custom animated illustrations were produced along with coordinating voiceovers and selecting stock footage.
              </p>
              <p className="paragraph">
                <strong>Localization</strong>
                <br />
                From January 2021 - September 2021, our design and development team was lent out to help the company’s US counterpart. During that time, new localized designs of the website were developed specific towards the US market.
              </p>
              <p className="paragraph">
                <strong>Zendesk</strong>
                <br />
                Implemented dynamic content for multilanguage and varying access roles in the NEQTO Zendesk. Set up anonymous and end user screens, also customized sidebar based on the user/access role.
              </p>
              <p className="paragraph">
                <strong>Confluence (Documentation)</strong>
                <br />
                Documented processes and step tutorials for team members and future developers on the team for the projects I was involved in.
              </p>
            </Text>
          </ProjectTile>
        </div>
        <div className="divider"></div>
        <div className="project-link-wrapper">
          <a className="text-link" href="https://neqto.jig-saw.com/ja/index.html" target="_blank">
            See the NEQTO Website
          </a>
        </div>
        <div className="divider"></div>
      </div>

      <ScrollTopBtn />
    </Layout>
  );
}
