import React from "react";

import Img from "react-cool-img";

import { Layout } from "~/components/layouts";
import { Breadcrumbs, ProjectHeader, ProjectTile, SkillWrapper, Skills, Overview, Tag, Text, ScrollTopBtn } from "~/components/elements";

import ConvertArrow from "~/assets/images/common/convert.svg";

import NeqtoLogin from "~/assets/images/works/apps/neqto/neqto-login.png";
import NeqtoDashboard from "~/assets/images/works/apps/neqto/neqto-dashboard.png";
import NeqtoScss from "~/assets/images/works/apps/neqto/neqto-scss.png";

export default function NeqtoApp() {
  return (
    <Layout>
      <Breadcrumbs
        works
        activeName="Web Apps"
      />

      <ProjectHeader
        title="NEQTO"
        date="2019 - Present"
        noPrevious
        nextLink="/works/web-apps/safing-app"
      />

      <div className="project-content">
        <ProjectTile image={NeqtoLogin} top>
          <SkillWrapper>
            <Skills title="Design">
              <Tag label="Adobe Illustrator" />
              <Tag label="Figma" />
            </Skills>

            <Skills title="Development">
              <Tag label="React JS" />
              <Tag label="SCSS" />
              <Tag label="Reactstrap" />
            </Skills>
          </SkillWrapper>
          <Overview title="Design Overhaul & UX Improvement">
            <p className="paragraph">
              After joining the team as the first designer with some developer experience, I led the redesign of the existing NEQTO console. The existing console design was revamped in its entirety to match the new branding, as well as improve user interaction and flow. The navigation structure was modified and improved along with other elements to ensure better understanding of the application.
            </p>
          </Overview>
        </ProjectTile>

        <div className="bg-blue">
          <ProjectTile image={NeqtoDashboard}>
            <Text title="Component Creation and Styling">
              <p className="paragraph">
                Since the application was undergoing a complete design overhaul, it was decided to redevelop the app as a whole as well, to improve the code structure and quality as well. During this time, after finalizing the new design, I was responsible for creating and styling the components of the application.
              </p>
            </Text>
          </ProjectTile>
        </div>

        <ProjectTile image={NeqtoScss}>
          <Text title="SCSS Implementation">
            <p className="paragraph">
              The application was initially developed with plain CSS, and after a year of multiple developers’ involvement, it resulted in a catastrophe of a file. I took the initiative to convert CSS to use SCSS, decluttering the existing CSS files. CSS libraries were separated and integrated as vendors. Styling for components was separated into partials, which were imported into the main.scss file. Variables and mix-ins for theming were now available for use after the conversion, as well as increased ease of maintenance.
            </p>
            <p className="paragraph convert">
              CSS
              <Img className="convert-arrow" src={ConvertArrow} />
              SCSS
            </p>
          </Text>
        </ProjectTile>

        <div className="divider"></div>
        <div className="project-link-wrapper">
          <a className="text-link" href="https://console.neqto.com/login" target="_blank">
            See the NEQTO Console
          </a>
        </div>
        <div className="divider"></div>
      </div>

      <ScrollTopBtn />
    </Layout>
  );
}
