import React from "react";

import Img from "react-cool-img";

import { Gradient, Layout } from "~/components/layouts";
import { Breadcrumbs, Tag } from "~/components/elements";

import HobbiesIcon from "~/assets/images/about/hobbies.png";
import MusicIcon from "~/assets/images/about/music.svg";
import GamesIcon from "~/assets/images/about/games.svg";

export default function About() {
  return (
    <Gradient>
      <Layout whiteGradient>
        <Breadcrumbs
          activeName="About Me"
        />
        <h1>About Me</h1>
        <div className="container">
          <div className="about-info">
            <p className="mb-2 desc">
              I am a designer turned developer, born and raised in Canada. After experiencing designing interface and website designs, learning to develop and bring the design or digital product to fruition felt extremely fulfilling and rewarding.
              <br /><br />
              From there, I have developed a passion for web development, creating cool, and fun new experiences for users. <span className="d-none d-lg-block"></span>Please see below for some highlights of my skills:
            </p>
            <p className="subheading">Design</p>
            <div className="mb-2">
              <Tag label="Figma" />
              <Tag label="Adobe Illustrator" />
              <Tag label="Adobe Photoshop" />
              <Tag label="Adobe After Effects" />
              <Tag label="Adobe Premiere Pro" />
              <Tag label="Adobe Animate CC" />
            </div>

            <p className="subheading">Development</p>
            <div>
              <Tag label="HTML5" />
              <Tag label="CSS" />
              <Tag label="SCSS" />
              <Tag label="Tailwind CSS" />
              <Tag label="JS/JQuery" />
              <Tag label="React JS" />
              <Tag label="React Transition Group" />
              <Tag label="Reactstrap" />
              <Tag label="AWS S3" />
              <Tag label="AWS Cloudfront" />
              <Tag label="Google Analytics" />
            </div>
          </div>

          <div className="divider mb-4"></div>

          <div className="about-category-wrapper">
            <div className="about-category">
              <div className="hobbies">
                <Img src={HobbiesIcon} debounce="0" />
              </div>
              <p className="about-category-title">
                Hobbies
              </p>
              <p className="about-category-list">
                Making Keycaps
                <br />
                3-D Printing
                <br />
                Badminton
                <br />
                Dance
                <br />
                Travelling
              </p>
            </div>
            <div className="about-category">
              <div className="music">
                <Img src={MusicIcon} debounce="0" />
              </div>
              <p className="about-category-title">
                Current Favourite Artists
              </p>
              <p className="about-category-list">
                NIKI
                <br />
                DEAN
                <br />
                DPR Live
                <br />
                Still Woozy
              </p>
            </div>
            <div className="about-category">
              <div className="games">
                <Img src={GamesIcon} debounce="0" />
              </div>
              <p className="about-category-title">
                Games played in 2022
              </p>
              <p className="about-category-list">
                Valorant
                <br />
                Raft
                <br />
                Pokemon Legends: Arceus
                <br />
                Ruined King: A League of Legends Story
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </Gradient>
  );
}
