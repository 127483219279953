import React from "react";
import { Link } from "react-router-dom";

import Img from "react-cool-img";

import { Layout } from "~/components/layouts";
import { Breadcrumbs, ProjectHeader, ProjectTile, SkillWrapper, Skills, Overview, Tag, Text, ScrollTopBtn } from "~/components/elements";

import ConvertArrow from "~/assets/images/common/convert.svg";

import SafingHero from "~/assets/images/works/websites/safing/safing-hero.png";
import SafingTemplate from "~/assets/images/works/websites/safing/safing-template.png";
import SafingForms from "~/assets/images/works/websites/safing/safing-forms.png";
import SafingBlog from "~/assets/images/works/websites/safing/safing-blog.png";
import Safing404 from "~/assets/images/works/websites/safing/safing-404.png";

export default function SafingWebsite() {
  return (
    <Layout>
      <Breadcrumbs
        works
        activeName="Websites"
      />

      <ProjectHeader
        title="Safing Website"
        date="2021 - Present"
        previousLink="/works/websites/neqto-website"
        nextLink="/works/websites/samsung-website"
      />

      <div className="project-content">
        <ProjectTile image={SafingHero} top>
          <SkillWrapper>
            <Skills title="Design">
              <Tag label="Adobe Illustrator" />
              <Tag label="Adobe Photoshop" />
              <Tag label="Figma" />
            </Skills>

            <Skills title="Development">
              <Tag label="HTML5" />
              <Tag label="CSS" />
              <Tag label="JS / JQuery" />
              <Tag label="AWS S3" />
              <Tag label="AWS Cloudfront" />
              <Tag label="AWS Lambda" />
            </Skills>
          </SkillWrapper>
          <Overview title="Design + Development">
            <p className="paragraph">
              I designed and developed the landing page for the new security service Safing. The design phase consisted of multiple layouts, color schemes and custom imagery. W3C standards (including color contrast and accessibility), as well as responsiveness were taken into account. After the final selection of the design, animated SVGs were implemented and the design was implemented with static HTML and CSS, which would later on be converted to use a template engine.
            </p>
          </Overview>
        </ProjectTile>

        <div className="bg-blue">
          <ProjectTile image={SafingTemplate}>
            <Text title="Template Engine Conversion">
              <p className="paragraph">
                Before leading the template engine conversion for <Link className="text-link" to={"/works/websites/neqto-website"}>NEQTO</Link>, I first experimented with Safing because I wanted to  create a more efficient and convenient process for developing. After much research, a template engine was chosen and implemented.
              </p>
              <p className="paragraph">
                Common files like the navigation were extracted as partial files. Macro files and variables were created to use the same template for multilanguage.
              </p>
              <p className="paragraph">
                The entire process was documented and outlined for the team and future reference.
              </p>
              <p className="paragraph convert">
                Static HTML, CSS, Asset Files
                <Img className="convert-arrow" src={ConvertArrow} />
                staticjinja Template Engine
              </p>
            </Text>
          </ProjectTile>
        </div>

        <ProjectTile image={SafingForms}>
          <Text title="Forms">
            <p className="paragraph">
              A new form page was implemented for the website. Form validation, error handling for API responses had to be set up. A JS address auto-fill library was also implemented.
            </p>
          </Text>
        </ProjectTile>

        <div className="bg-blue">
          <ProjectTile image={SafingBlog}>
            <Text title="Blog Templating Conversion">
              <p className="paragraph">
                Before converting <Link className="text-link" to={"/works/websites/neqto-website"}>NEQTO's</Link> blog, I first experimented blog templating with the Safing website. Since blogs have not yet been written, but the set up for a template engine was desired, I decided to test out Pelican the blog template engine, which also uses Jinja2.
              </p>
              <p className="paragraph">
                The blog template engine was set up, automating category generation and article generation along with preset styling. An SNS plugin and Highlights.js was also implemented to easily include share links, and to make code blocks in the blogs easier to read, respectively. All that is needed to generate a new blog is a metadata file and a markdown file containing the article content.
              </p>
              <p className="paragraph">
                With the blog templating engine implemented, a developer is no longer needed to manually insert the blog content into the website using HTML and CSS. The process and a step tutorial was written forthe team and future reference.
              </p>
              <p className="paragraph convert">
                Manually writing blogs in HTML & CSS
                <Img className="convert-arrow" src={ConvertArrow} />
                Pelican Blog Template Engine
              </p>
            </Text>
          </ProjectTile>
        </div>

        <ProjectTile image={Safing404}>
          <Text title="Multilanguage setup">
            <p className="paragraph">
              Set up 404 pages and language redirecting with AWS Cloudfront and AWS Lambda functions. Also set up the language switcher in the navigation.
            </p>
          </Text>
        </ProjectTile>

        <div className="divider"></div>
        <div className="project-link-wrapper">
          <a className="text-link" href="https://safing.com/ja/" target="_blank">
            See the Safing Website
          </a>
        </div>
        <div className="divider"></div>
      </div>

      <ScrollTopBtn />
    </Layout>
  );
}
