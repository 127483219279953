export default function WorksIcon() {
  return (
    <div className="works-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 410.81 359.96"
        >
        <defs>
          <linearGradient
            id="works-linear-gradient"
            x1="142.18"
            y1="357.83"
            x2="348.34"
            y2="237.16"
            gradientTransform="matrix(-1, 0, 0, 1, 496, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#353941" />
            <stop offset="0.16" stopColor="#373c47" />
            <stop offset="0.38" stopColor="#3c4658" />
            <stop offset="0.62" stopColor="#445574" />
            <stop offset="0.89" stopColor="#506b9b" />
            <stop offset={1} stopColor="#5575ad" />
          </linearGradient>
          <linearGradient
            id="works-linear-gradient-2"
            x1="359.74"
            y1="25.8"
            x2="257.59"
            y2="202.75"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#5f85db" />
            <stop offset="0.49" stopColor="#f9bc60" />
            <stop offset={1} stopColor="#f792b4" />
          </linearGradient>
          <linearGradient
            id="works-linear-gradient-3"
            x1="70.5"
            y1="224.71"
            x2="23.5"
            y2="306.13"
            xlinkHref="#works-linear-gradient-2"
          />
          <linearGradient
            id="works-linear-gradient-4"
            x1="383.4"
            y1="56.89"
            x2="302.3"
            y2="200.85"
            gradientTransform="matrix(-1, 0, 0, 1, 496, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#353941" />
            <stop offset="0.12" stopColor="#373c47" />
            <stop offset="0.27" stopColor="#3c4658" />
            <stop offset="0.45" stopColor="#445574" />
            <stop offset="0.64" stopColor="#506b9b" />
            <stop offset="0.72" stopColor="#5575ad" />
            <stop offset="0.78" stopColor="#5473a9" />
            <stop offset="0.83" stopColor="#506c9c" />
            <stop offset="0.88" stopColor="#4a6088" />
            <stop offset="0.94" stopColor="#41506b" />
            <stop offset="0.99" stopColor="#363c46" />
            <stop offset={1} stopColor="#353941" />
          </linearGradient>
          <linearGradient
            id="works-linear-gradient-5"
            x1="127.41"
            y1="329.74"
            x2="356.48"
            y2="195.67"
            xlinkHref="#works-linear-gradient"
          />
          <linearGradient
            id="works-linear-gradient-6"
            x1="240.41"
            y1="174.53"
            x2="256.96"
            y2="174.53"
            gradientTransform="matrix(-1, 0, 0, 1, 496, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#353941" />
            <stop offset="0.16" stopColor="#363b45" />
            <stop offset="0.32" stopColor="#3a4152" />
            <stop offset="0.48" stopColor="#3f4b66" />
            <stop offset="0.65" stopColor="#475a83" />
            <stop offset="0.82" stopColor="#516ca9" />
            <stop offset="0.98" stopColor="#5e83d6" />
            <stop offset={1} stopColor="#5f85db" />
          </linearGradient>
          <linearGradient
            id="works-linear-gradient-7"
            x1="251.28"
            y1="180.81"
            x2="267.83"
            y2="180.81"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-8"
            x1="262.15"
            y1="187.08"
            x2="278.7"
            y2="187.08"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-9"
            x1="273.02"
            y1="193.36"
            x2="289.57"
            y2="193.36"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-10"
            x1="283.88"
            y1="199.63"
            x2="300.44"
            y2="199.63"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-11"
            x1="294.75"
            y1="205.91"
            x2="311.3"
            y2="205.91"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-12"
            x1="305.62"
            y1="212.18"
            x2="322.17"
            y2="212.18"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-13"
            x1="316.49"
            y1="218.46"
            x2="333.04"
            y2="218.46"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-14"
            x1="327.35"
            y1="224.73"
            x2="343.91"
            y2="224.73"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-15"
            x1="338.22"
            y1="231.01"
            x2="354.77"
            y2="231.01"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-16"
            x1="349.09"
            y1="237.28"
            x2="365.64"
            y2="237.28"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-17"
            x1="359.96"
            y1="243.56"
            x2="376.51"
            y2="243.56"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-18"
            x1="370.83"
            y1="249.83"
            x2="387.38"
            y2="249.83"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-19"
            x1="381.69"
            y1="256.11"
            x2="398.25"
            y2="256.11"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-20"
            x1="227.15"
            y1="184.49"
            x2="251.63"
            y2="184.49"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-21"
            x1="248.88"
            y1="194.75"
            x2="265.43"
            y2="194.75"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-22"
            x1="259.75"
            y1="201.03"
            x2="276.3"
            y2="201.03"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-23"
            x1="270.62"
            y1="207.3"
            x2="287.17"
            y2="207.3"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-24"
            x1="281.48"
            y1="213.58"
            x2="298.04"
            y2="213.58"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-25"
            x1="292.35"
            y1="219.85"
            x2="308.9"
            y2="219.85"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-26"
            x1="303.22"
            y1="226.13"
            x2="319.77"
            y2="226.13"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-27"
            x1="314.09"
            y1="232.4"
            x2="330.64"
            y2="232.4"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-28"
            x1="324.95"
            y1="238.67"
            x2="341.51"
            y2="238.67"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-29"
            x1="335.82"
            y1="244.95"
            x2="352.37"
            y2="244.95"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-30"
            x1="346.69"
            y1="251.22"
            x2="363.24"
            y2="251.22"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-31"
            x1="357.56"
            y1="257.5"
            x2="374.11"
            y2="257.5"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-32"
            x1="368.43"
            y1="263.77"
            x2="384.98"
            y2="263.77"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-33"
            x1="213.75"
            y1="192.17"
            x2="238.24"
            y2="192.17"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-34"
            x1="235.48"
            y1="202.43"
            x2="252.04"
            y2="202.43"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-35"
            x1="246.35"
            y1="208.7"
            x2="262.9"
            y2="208.7"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-36"
            x1="257.22"
            y1="214.98"
            x2="273.77"
            y2="214.98"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-37"
            x1="268.09"
            y1="221.25"
            x2="284.64"
            y2="221.25"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-38"
            x1="278.96"
            y1="227.53"
            x2="295.51"
            y2="227.53"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-39"
            x1="289.82"
            y1="233.8"
            x2="306.38"
            y2="233.8"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-40"
            x1="300.69"
            y1="240.08"
            x2="317.24"
            y2="240.08"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-41"
            x1="311.56"
            y1="246.35"
            x2="328.11"
            y2="246.35"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-42"
            x1="322.43"
            y1="252.63"
            x2="338.98"
            y2="252.63"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-43"
            x1="333.29"
            y1="258.9"
            x2="349.85"
            y2="258.9"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-44"
            x1="347.09"
            y1="269.16"
            x2="371.58"
            y2="269.16"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-45"
            x1="200.42"
            y1="199.93"
            x2="224.91"
            y2="199.93"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-46"
            x1="222.15"
            y1="210.19"
            x2="238.71"
            y2="210.19"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-47"
            x1="233.02"
            y1="216.47"
            x2="249.57"
            y2="216.47"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-48"
            x1="243.89"
            y1="222.74"
            x2="260.44"
            y2="222.74"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-49"
            x1="254.76"
            y1="229.02"
            x2="271.31"
            y2="229.02"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-50"
            x1="265.62"
            y1="235.29"
            x2="282.18"
            y2="235.29"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-51"
            x1="276.49"
            y1="241.57"
            x2="293.04"
            y2="241.57"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-52"
            x1="287.36"
            y1="247.84"
            x2="303.91"
            y2="247.84"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-53"
            x1="298.23"
            y1="254.12"
            x2="314.78"
            y2="254.12"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-54"
            x1="309.1"
            y1="260.39"
            x2="325.65"
            y2="260.39"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-55"
            x1="319.96"
            y1="266.67"
            x2="336.52"
            y2="266.67"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-56"
            x1="333.76"
            y1="276.92"
            x2="358.25"
            y2="276.92"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-57"
            x1="208.87"
            y1="217.84"
            x2="225.43"
            y2="217.84"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-58"
            x1="219.74"
            y1="224.12"
            x2="236.29"
            y2="224.12"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-59"
            x1="230.61"
            y1="242.94"
            x2="290.63"
            y2="242.94"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-60"
            x1="284.95"
            y1="261.77"
            x2="301.5"
            y2="261.77"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-61"
            x1="295.82"
            y1="268.04"
            x2="312.37"
            y2="268.04"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-62"
            x1="306.68"
            y1="274.32"
            x2="323.24"
            y2="274.32"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-63"
            x1="328.57"
            y1="286.88"
            x2="344.97"
            y2="286.88"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-64"
            x1="317.36"
            y1="280.89"
            x2="333.91"
            y2="280.89"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-65"
            x1="187.14"
            y1="205.29"
            x2="203.69"
            y2="205.29"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-66"
            x1="198.01"
            y1="211.57"
            x2="214.56"
            y2="211.57"
            xlinkHref="#works-linear-gradient-6"
          />
          <linearGradient
            id="works-linear-gradient-67"
            x1="117.5"
            y1="229.33"
            x2="117.51"
            y2="229.33"
            gradientTransform="matrix(-1, 0, 0, 1, 496, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#353941" />
            <stop offset={1} stopColor="#353941" />
          </linearGradient>
          <linearGradient
            id="works-linear-gradient-68"
            x1="117.5"
            y1="282.61"
            x2="432.67"
            y2="282.61"
            xlinkHref="#works-linear-gradient-67"
          />
          <linearGradient
            id="works-linear-gradient-69"
            x1={253}
            y1="130.98"
            x2="437.5"
            y2="130.98"
            xlinkHref="#works-linear-gradient-67"
          />
        </defs>
        <g>
          <g>
            <path
              className="cls-1"
              d="M361.62,268.9,206.34,358.56c-3.23,1.86-11.3,1.86-14.53,0L79.09,293.32l165-95.09,117.57,67.88C363.12,267,363.12,268,361.62,268.9Z"
            />
            <circle className="cls-2" cx="308.66" cy="114.27" r="102.14" />
            <circle className="cls-3" cx={47} cy="265.42" r={47} />
            <path
              className="cls-4"
              d="M66,102.56,238.56,2.94a5.38,5.38,0,0,1,8.07,4.66V152.23L63.33,258.06V107.22A5.4,5.4,0,0,1,66,102.56Z"
            />
            <path
              className="cls-5"
              d="M377.25,230.94,204.72,330.55c-3.58,2.08-12.55,2.08-16.14,0L63.33,258.06,246.62,152.41l130.63,75.42C378.92,228.79,378.92,230,377.25,230.94Z"
            />
            <path
              className="cls-6"
              d="M249.72,178.94l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.16,4.16,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.52.27,2.16l6,3.48A4.17,4.17,0,0,0,249.72,178.94Z"
            />
            <path
              className="cls-7"
              d="M238.86,185.21l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,238.86,185.21Z"
            />
            <path
              className="cls-8"
              d="M228,191.49l5.21-3c1-.56.84-1.53-.27-2.17l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.56-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,228,191.49Z"
            />
            <path
              className="cls-9"
              d="M217.12,197.76l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,217.12,197.76Z"
            />
            <path
              className="cls-10"
              d="M206.25,204l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.17,4.17,0,0,0,206.25,204Z"
            />
            <path
              className="cls-11"
              d="M195.38,210.31l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.55-.84,1.52.26,2.16l6,3.48A4.13,4.13,0,0,0,195.38,210.31Z"
            />
            <path
              className="cls-12"
              d="M184.52,216.59l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,184.52,216.59Z"
            />
            <path
              className="cls-13"
              d="M173.65,222.86l5.21-3c1-.56.84-1.52-.26-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.48A4.15,4.15,0,0,0,173.65,222.86Z"
            />
            <path
              className="cls-14"
              d="M162.78,229.14l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,162.78,229.14Z"
            />
            <path
              className="cls-15"
              d="M151.91,235.41l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.48A4.16,4.16,0,0,0,151.91,235.41Z"
            />
            <path
              className="cls-16"
              d="M141.05,241.69l5.21-3c1-.55.84-1.52-.27-2.16L140,233a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,141.05,241.69Z"
            />
            <path
              className="cls-17"
              d="M130.18,248l5.21-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,130.18,248Z"
            />
            <path
              className="cls-18"
              d="M119.31,254.23l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,119.31,254.23Z"
            />
            <path
              className="cls-19"
              d="M108.44,260.51l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.17,4.17,0,0,0,108.44,260.51Z"
            />
            <path
              className="cls-20"
              d="M255.05,191.19l13.16-7.6c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15L245,185.39c-1,.56-.84,1.52.26,2.16l6,3.48A4.17,4.17,0,0,0,255.05,191.19Z"
            />
            <path
              className="cls-21"
              d="M241.25,199.16l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.26,2.16l6,3.48A4.17,4.17,0,0,0,241.25,199.16Z"
            />
            <path
              className="cls-22"
              d="M230.39,205.43l5.21-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.16,4.16,0,0,0,230.39,205.43Z"
            />
            <path
              className="cls-23"
              d="M219.52,211.7l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,219.52,211.7Z"
            />
            <path
              className="cls-24"
              d="M208.65,218l5.22-3c1-.56.84-1.52-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,208.65,218Z"
            />
            <path
              className="cls-25"
              d="M197.78,224.25l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.13,4.13,0,0,0,197.78,224.25Z"
            />
            <path
              className="cls-26"
              d="M186.92,230.53l5.21-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,186.92,230.53Z"
            />
            <path
              className="cls-27"
              d="M176.05,236.8l5.21-3c1-.55.84-1.52-.26-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15L166,231c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,176.05,236.8Z"
            />
            <path
              className="cls-28"
              d="M165.18,243.08l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,165.18,243.08Z"
            />
            <path
              className="cls-29"
              d="M154.31,249.35l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.13,4.13,0,0,0,154.31,249.35Z"
            />
            <path
              className="cls-30"
              d="M143.44,255.63l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.26,2.17l6,3.47A4.17,4.17,0,0,0,143.44,255.63Z"
            />
            <path
              className="cls-31"
              d="M132.58,261.9l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,132.58,261.9Z"
            />
            <path
              className="cls-32"
              d="M121.71,268.18l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.56-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,121.71,268.18Z"
            />
            <path
              className="cls-33"
              d="M268.45,198.86l13.15-7.59c1-.55.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-13.15,7.6c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,268.45,198.86Z"
            />
            <path
              className="cls-34"
              d="M254.65,206.83l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,254.65,206.83Z"
            />
            <path
              className="cls-35"
              d="M243.78,213.11l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.56-.84,1.52.27,2.16l6,3.48A4.17,4.17,0,0,0,243.78,213.11Z"
            />
            <path
              className="cls-36"
              d="M232.92,219.38l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,232.92,219.38Z"
            />
            <path
              className="cls-37"
              d="M222.05,225.66l5.21-3c1-.55.84-1.52-.27-2.16L221,217a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,222.05,225.66Z"
            />
            <path
              className="cls-38"
              d="M211.18,231.93l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,211.18,231.93Z"
            />
            <path
              className="cls-39"
              d="M200.31,238.21l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.17,4.17,0,0,0,200.31,238.21Z"
            />
            <path
              className="cls-40"
              d="M189.44,244.48l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.26,2.17l6,3.48A4.13,4.13,0,0,0,189.44,244.48Z"
            />
            <path
              className="cls-41"
              d="M178.58,250.76l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,178.58,250.76Z"
            />
            <path
              className="cls-42"
              d="M167.71,257l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.16,4.16,0,0,0,167.71,257Z"
            />
            <path
              className="cls-43"
              d="M156.84,263.31l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,156.84,263.31Z"
            />
            <path
              className="cls-44"
              d="M135.11,275.85l13.15-7.59c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-13.15,7.59c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,135.11,275.85Z"
            />
            <path
              className="cls-45"
              d="M281.78,206.63l13.15-7.6c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-13.15,7.59c-1,.56-.84,1.53.27,2.17l6,3.47A4.17,4.17,0,0,0,281.78,206.63Z"
            />
            <path
              className="cls-46"
              d="M268,214.6l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.17,4.17,0,0,0,268,214.6Z"
            />
            <path
              className="cls-47"
              d="M257.11,220.87l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.26,2.17l6,3.48A4.13,4.13,0,0,0,257.11,220.87Z"
            />
            <path
              className="cls-48"
              d="M246.25,227.15l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,246.25,227.15Z"
            />
            <path
              className="cls-49"
              d="M235.38,233.42l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.48A4.18,4.18,0,0,0,235.38,233.42Z"
            />
            <path
              className="cls-50"
              d="M224.51,239.7l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,224.51,239.7Z"
            />
            <path
              className="cls-51"
              d="M213.64,246l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.17,4.17,0,0,0,213.64,246Z"
            />
            <path
              className="cls-52"
              d="M202.78,252.24l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,202.78,252.24Z"
            />
            <path
              className="cls-53"
              d="M191.91,258.52l5.21-3c1-.56.84-1.52-.26-2.17l-6-3.47a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,191.91,258.52Z"
            />
            <path
              className="cls-54"
              d="M181,264.79l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15L171,259c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,181,264.79Z"
            />
            <path
              className="cls-55"
              d="M170.17,271.07l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.17,4.17,0,0,0,170.17,271.07Z"
            />
            <path
              className="cls-56"
              d="M148.44,283.62,161.59,276c1-.56.84-1.53-.27-2.17l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-13.15,7.59c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,148.44,283.62Z"
            />
            <path
              className="cls-57"
              d="M281.26,222.25l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.52.27,2.17l6,3.47A4.17,4.17,0,0,0,281.26,222.25Z"
            />
            <path
              className="cls-58"
              d="M270.39,228.52l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.26,2.16l6,3.48A4.13,4.13,0,0,0,270.39,228.52Z"
            />
            <path
              className="cls-59"
              d="M216.06,259.9l48.68-28.11c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16L206,254.1c-1,.56-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,216.06,259.9Z"
            />
            <path
              className="cls-60"
              d="M205.19,266.17l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,205.19,266.17Z"
            />
            <path
              className="cls-61"
              d="M194.32,272.45l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,194.32,272.45Z"
            />
            <path
              className="cls-62"
              d="M183.45,278.72l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.13,4.13,0,0,0,183.45,278.72Z"
            />
            <path
              className="cls-63"
              d="M161.72,291.27l5.07-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.07,3c-1,.56-.84,1.53.27,2.17l6,3.48A4.18,4.18,0,0,0,161.72,291.27Z"
            />
            <path
              className="cls-64"
              d="M172.78,285.29l5.21-3c1-.55.84-1.52-.26-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,172.78,285.29Z"
            />
            <path
              className="cls-65"
              d="M303,209.7l5.21-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,303,209.7Z"
            />
            <path
              className="cls-66"
              d="M292.13,216l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,292.13,216Z"
            />
            <path
              className="cls-67"
              d="M249.72,178.27l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.17,4.17,0,0,0,249.72,178.27Z"
            />
            <path
              className="cls-67"
              d="M238.86,184.54l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,238.86,184.54Z"
            />
            <path
              className="cls-67"
              d="M228,190.82l5.21-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16L218,185c-1,.56-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,228,190.82Z"
            />
            <path
              className="cls-67"
              d="M217.12,197.09l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,217.12,197.09Z"
            />
            <path
              className="cls-67"
              d="M206.25,203.37l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.48a4.18,4.18,0,0,0-3.75-.15l-5.22,3c-1,.56-.84,1.52.27,2.16l6,3.48A4.17,4.17,0,0,0,206.25,203.37Z"
            />
            <path
              className="cls-67"
              d="M195.38,209.64l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.55-.84,1.52.26,2.16l6,3.48A4.13,4.13,0,0,0,195.38,209.64Z"
            />
            <path
              className="cls-67"
              d="M184.52,215.92l5.21-3c1-.56.84-1.53-.27-2.17l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.56-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,184.52,215.92Z"
            />
            <path
              className="cls-67"
              d="M173.65,222.19l5.21-3c1-.55.84-1.52-.26-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,173.65,222.19Z"
            />
            <path
              className="cls-67"
              d="M162.78,228.47l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,162.78,228.47Z"
            />
            <path
              className="cls-67"
              d="M151.91,234.74l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.13,4.13,0,0,0,151.91,234.74Z"
            />
            <path
              className="cls-67"
              d="M141.05,241l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,141.05,241Z"
            />
            <path
              className="cls-67"
              d="M130.18,247.29l5.21-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.27,2.17l6,3.48A4.15,4.15,0,0,0,130.18,247.29Z"
            />
            <path
              className="cls-67"
              d="M119.31,253.57l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,119.31,253.57Z"
            />
            <path
              className="cls-67"
              d="M108.44,259.84l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.13,4.13,0,0,0,108.44,259.84Z"
            />
            <path
              className="cls-67"
              d="M255.05,190.52l13.16-7.6c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15L245,184.72c-1,.56-.84,1.53.26,2.17l6,3.47A4.17,4.17,0,0,0,255.05,190.52Z"
            />
            <path
              className="cls-67"
              d="M241.25,198.49l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.26,2.16l6,3.48A4.17,4.17,0,0,0,241.25,198.49Z"
            />
            <path
              className="cls-67"
              d="M230.39,204.76l5.21-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.27,2.17l6,3.48A4.15,4.15,0,0,0,230.39,204.76Z"
            />
            <path
              className="cls-67"
              d="M219.52,211l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,219.52,211Z"
            />
            <path
              className="cls-67"
              d="M208.65,217.31l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.48A4.18,4.18,0,0,0,208.65,217.31Z"
            />
            <path
              className="cls-67"
              d="M197.78,223.59l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.17,4.17,0,0,0,197.78,223.59Z"
            />
            <path
              className="cls-67"
              d="M186.92,229.86l5.21-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,186.92,229.86Z"
            />
            <path
              className="cls-67"
              d="M176.05,236.13l5.21-3c1-.55.84-1.52-.26-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,176.05,236.13Z"
            />
            <path
              className="cls-67"
              d="M165.18,242.41l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,165.18,242.41Z"
            />
            <path
              className="cls-67"
              d="M154.31,248.68l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.13,4.13,0,0,0,154.31,248.68Z"
            />
            <path
              className="cls-67"
              d="M143.44,255l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.26,2.17l6,3.47A4.17,4.17,0,0,0,143.44,255Z"
            />
            <path
              className="cls-67"
              d="M132.58,261.23l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,132.58,261.23Z"
            />
            <path
              className="cls-67"
              d="M121.71,267.51l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,121.71,267.51Z"
            />
            <path
              className="cls-67"
              d="M268.45,198.2l13.15-7.6c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-13.15,7.59c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,268.45,198.2Z"
            />
            <path
              className="cls-67"
              d="M254.65,206.16l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,254.65,206.16Z"
            />
            <path
              className="cls-67"
              d="M243.78,212.44l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.52.27,2.16l6,3.48A4.17,4.17,0,0,0,243.78,212.44Z"
            />
            <path
              className="cls-67"
              d="M232.92,218.71l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,232.92,218.71Z"
            />
            <path
              className="cls-67"
              d="M222.05,225l5.21-3c1-.56.84-1.53-.27-2.17l-6-3.48a4.18,4.18,0,0,0-3.75-.15l-5.21,3c-1,.56-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,222.05,225Z"
            />
            <path
              className="cls-67"
              d="M211.18,231.26l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,211.18,231.26Z"
            />
            <path
              className="cls-67"
              d="M200.31,237.54l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.56-.84,1.52.27,2.16l6,3.48A4.17,4.17,0,0,0,200.31,237.54Z"
            />
            <path
              className="cls-67"
              d="M189.44,243.81l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.55-.84,1.52.26,2.16l6,3.48A4.13,4.13,0,0,0,189.44,243.81Z"
            />
            <path
              className="cls-67"
              d="M178.58,250.09l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,178.58,250.09Z"
            />
            <path
              className="cls-67"
              d="M167.71,256.36l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.48A4.15,4.15,0,0,0,167.71,256.36Z"
            />
            <path
              className="cls-67"
              d="M156.84,262.64l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,156.84,262.64Z"
            />
            <path
              className="cls-67"
              d="M135.11,275.19l13.15-7.6c1-.55.84-1.52-.27-2.16L142,262a4.13,4.13,0,0,0-3.74-.15l-13.15,7.59c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,135.11,275.19Z"
            />
            <path
              className="cls-67"
              d="M281.78,206l13.15-7.59c1-.56.84-1.53-.27-2.17l-6-3.47a4.16,4.16,0,0,0-3.75-.16l-13.15,7.59c-1,.56-.84,1.53.27,2.17l6,3.47A4.17,4.17,0,0,0,281.78,206Z"
            />
            <path
              className="cls-67"
              d="M268,213.93l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.56-.84,1.52.27,2.16l6,3.48A4.17,4.17,0,0,0,268,213.93Z"
            />
            <path
              className="cls-67"
              d="M257.11,220.2l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.55-.84,1.52.26,2.16l6,3.48A4.13,4.13,0,0,0,257.11,220.2Z"
            />
            <path
              className="cls-67"
              d="M246.25,226.48l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,246.25,226.48Z"
            />
            <path
              className="cls-67"
              d="M235.38,232.75l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,235.38,232.75Z"
            />
            <path
              className="cls-67"
              d="M224.51,239l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,224.51,239Z"
            />
            <path
              className="cls-67"
              d="M213.64,245.3l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.48A4.13,4.13,0,0,0,213.64,245.3Z"
            />
            <path
              className="cls-67"
              d="M202.78,251.58l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,202.78,251.58Z"
            />
            <path
              className="cls-67"
              d="M191.91,257.85l5.21-3c1-.56.84-1.52-.26-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.48A4.18,4.18,0,0,0,191.91,257.85Z"
            />
            <path
              className="cls-67"
              d="M181,264.13l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,181,264.13Z"
            />
            <path
              className="cls-67"
              d="M170.17,270.4l5.22-3c1-.56.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.17,4.17,0,0,0,170.17,270.4Z"
            />
            <path
              className="cls-67"
              d="M148.44,283l13.15-7.59c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-13.15,7.59c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,148.44,283Z"
            />
            <path
              className="cls-67"
              d="M281.26,221.58l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.17,4.17,0,0,0,281.26,221.58Z"
            />
            <path
              className="cls-67"
              d="M270.39,227.85l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.15,4.15,0,0,0-3.75-.15l-5.21,3c-1,.55-.84,1.52.26,2.16l6,3.48A4.13,4.13,0,0,0,270.39,227.85Z"
            />
            <path
              className="cls-67"
              d="M216.06,259.23l48.68-28.11c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16L206,253.43c-1,.56-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,216.06,259.23Z"
            />
            <path
              className="cls-67"
              d="M205.19,265.5l5.21-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,205.19,265.5Z"
            />
            <path
              className="cls-67"
              d="M194.32,271.78l5.22-3c1-.56.84-1.53-.27-2.17l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.56-.84,1.52.27,2.16l6,3.48A4.19,4.19,0,0,0,194.32,271.78Z"
            />
            <path
              className="cls-67"
              d="M183.45,278.05l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.19,4.19,0,0,0-3.75-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.13,4.13,0,0,0,183.45,278.05Z"
            />
            <path
              className="cls-67"
              d="M161.72,290.6l5.07-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.07,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,161.72,290.6Z"
            />
            <path
              className="cls-67"
              d="M172.78,284.62l5.21-3c1-.55.84-1.52-.26-2.16l-6-3.48a4.17,4.17,0,0,0-3.74-.16l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,172.78,284.62Z"
            />
            <path
              className="cls-67"
              d="M303,209l5.21-3c1-.56.84-1.53-.27-2.17l-6-3.47a4.19,4.19,0,0,0-3.75-.16l-5.21,3c-1,.56-.84,1.53.27,2.17l6,3.47A4.19,4.19,0,0,0,303,209Z"
            />
            <path
              className="cls-67"
              d="M292.13,215.3l5.22-3c1-.55.84-1.52-.27-2.16l-6-3.48a4.13,4.13,0,0,0-3.74-.15l-5.22,3c-1,.55-.84,1.52.27,2.16l6,3.48A4.15,4.15,0,0,0,292.13,215.3Z"
            />
            <path
              className="cls-68"
              d="M256.62,288.85l48.69-28.12c1-.55.84-1.52-.27-2.16l-30.63-17.68a4.13,4.13,0,0,0-3.74-.15L222,268.85c-1,.55-.84,1.52.27,2.16l30.62,17.68A4.19,4.19,0,0,0,256.62,288.85Z"
            />
            <path
              className="cls-69"
              d="M378.49,229.28h0v.1S378.49,229.31,378.49,229.28Z"
            />
            <path
              className="cls-70"
              d="M63.33,258.06h0l125.25,72.5c3.59,2.07,12.56,2.07,16.14,0l172.53-99.61c.83-.48,1.25-1,1.25-1.56v3.8c0,.51-.44,1-1.24,1.48L204.72,334.28c-3.58,2.07-12.55,2.07-16.14,0L63.33,262v-3.91Z"
            />
            <path
              className="cls-71"
              d="M61.19,100.35,233.73.72A5.33,5.33,0,0,1,238.3.35s.43.18.61.27L243,2.51h0a5.32,5.32,0,0,0-4.43.42L66,102.56a5.4,5.4,0,0,0-2.69,4.66V258.06h0V262l-2.27-1.38A5.33,5.33,0,0,1,58.5,256V105A5.38,5.38,0,0,1,61.19,100.35Z"
            />
            <polygon
              className="cls-72"
              points="73.52 106.79 236.45 12.71 236.45 149.55 73.52 243.63 73.52 106.79"
            />
            <path
              className="cls-73"
              d="M141.45,121.58l-4.1,23.79-3.87,2.23-2.18-10c0-.19-.1-.49-.17-.89s-.14-.84-.22-1.31-.16-.92-.23-1.34a8.51,8.51,0,0,1-.12-1c0,.29-.06.68-.13,1.18s-.13,1-.21,1.58-.15,1.08-.22,1.57-.14.87-.18,1.14L127.66,151l-3.87,2.23-4.1-19,3.35-1.94,2,10.52c.09.41.19.93.29,1.57s.21,1.28.3,1.93.15,1.2.2,1.65c0-.52.11-1.16.2-1.9s.18-1.46.28-2.17.19-1.26.26-1.66L129,128.79l3.22-1.85,2.34,10.65c.07.31.16.77.26,1.36s.19,1.22.28,1.86.16,1.2.2,1.66c.05-.53.11-1.16.2-1.9s.19-1.5.31-2.25.2-1.39.28-1.92l2-12.88Z"
            />
            <path
              className="cls-73"
              d="M153.91,127.59a15.58,15.58,0,0,1-1.61,7.23,11.13,11.13,0,0,1-4.37,4.74,4.64,4.64,0,0,1-3.05.77,2.86,2.86,0,0,1-2.1-1.68,9.2,9.2,0,0,1-.77-4.19,15.45,15.45,0,0,1,1.6-7.19,11.19,11.19,0,0,1,4.39-4.73,4.68,4.68,0,0,1,3.06-.77,2.89,2.89,0,0,1,2.09,1.67A9.09,9.09,0,0,1,153.91,127.59Zm-8.48,4.9a6.48,6.48,0,0,0,.6,3.33c.39.6,1,.64,1.94.12a4.39,4.39,0,0,0,1.93-2.36,11.77,11.77,0,0,0,.59-4,6.42,6.42,0,0,0-.59-3.3q-.6-.87-2-.09A4.38,4.38,0,0,0,146,128.5,11.25,11.25,0,0,0,145.43,132.49Z"
            />
            <path
              className="cls-73"
              d="M163.54,113.57c.17-.1.36-.2.59-.31a3.22,3.22,0,0,1,.54-.22l-.25,4.34a3.54,3.54,0,0,0-.47.17,5.11,5.11,0,0,0-.52.25,6.77,6.77,0,0,0-1.64,1.36,6.59,6.59,0,0,0-1.27,2,7,7,0,0,0-.49,2.73v8.34l-3.35,1.94V117.83l2.54-1.47.5,2.48.16-.09a13.42,13.42,0,0,1,1.48-3A6.5,6.5,0,0,1,163.54,113.57Z"
            />
            <path
              className="cls-73"
              d="M170.25,103.58v10.2c0,.62,0,1.24-.06,1.87l-.12,1.92,0,0,.7-1.66c.24-.56.5-1.11.77-1.67l3.44-7,3.78-2.18L173.92,115l5.17,6.28-3.87,2.24-3.53-4.59-1.44,2.36v5.1l-3.35,1.93v-22.8Z"
            />
            <path
              className="cls-73"
              d="M189.42,110.45A8.4,8.4,0,0,1,188.1,115a11,11,0,0,1-3.92,3.61,12.31,12.31,0,0,1-2.21,1,6,6,0,0,1-1.84.33v-3.69a8.19,8.19,0,0,0,2.13-.24,7.4,7.4,0,0,0,2-.78,4.11,4.11,0,0,0,1.39-1.19,2.25,2.25,0,0,0,.43-1.27,1.09,1.09,0,0,0-.17-.65,1.08,1.08,0,0,0-.73-.33,9.3,9.3,0,0,0-1.75-.07,6.42,6.42,0,0,1-1.91-.21,1.73,1.73,0,0,1-1.09-.93,4.71,4.71,0,0,1-.36-2.08,7.15,7.15,0,0,1,1.33-4.19A10.77,10.77,0,0,1,185,101.2a10.16,10.16,0,0,1,2.19-1,6.32,6.32,0,0,1,2.13-.24l-1,3.79a13.19,13.19,0,0,0-1.71.13,4.84,4.84,0,0,0-1.64.61c-1,.57-1.49,1.21-1.49,1.93a.9.9,0,0,0,.19.6,1.15,1.15,0,0,0,.75.25c.36,0,.91,0,1.63,0a7.2,7.2,0,0,1,1.82.14,1.78,1.78,0,0,1,1.18.86A4.21,4.21,0,0,1,189.42,110.45Z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
