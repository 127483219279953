import React from "react";

import { Layout } from "~/components/layouts";
import { Breadcrumbs, ProjectHeader, ProjectTile, SkillWrapper, Skills, Overview, Tag, Text, ScrollTopBtn } from "~/components/elements";

import CIBCHero from "~/assets/images/works/websites/cibc/cibc-hero.png";

export default function CIBCWebsite() {
  return (
    <Layout>
      <Breadcrumbs
        works
        activeName="Websites"
      />

      <ProjectHeader
        title="CIBC Website"
        date="2018 - 2019"
        previousLink="/works/websites/sunlife-website"
        noNext
      />

      <div className="project-content">
        <ProjectTile image={CIBCHero} top>
          <SkillWrapper>
            <Skills title="Design">
              <Tag label="Adobe Illustrator" />
              <Tag label="Adobe Animate CC" />
            </Skills>

            <Skills title="Development">
              <Tag label="JS" />
            </Skills>
          </SkillWrapper>
          <Overview title="Interactive Video Creation">
            <p className="paragraph">
              This project was to create an informative and interactive video of CIBC’s SmartBanking tool. A storyboard was first made to explore the video sequence and the user flow; designated illustrations were created and assets were gathered. After, the video development was completed with Adobe Animate CC, exported into HTML5 Canvas. Short JS scripts were needed for the interactive buttons on the dashboard that would jump to different portions of the video sequence.
            </p>
          </Overview>
        </ProjectTile>

        <div className="divider mt-5"></div>
        <div className="project-link-wrapper">
          <a className="text-link" href="https://www.cibc.com/en/business/cash-management/smartbanking/video.html" target="_blank">
            See the CIBC Website
          </a>
        </div>
        <div className="divider"></div>
      </div>

      <ScrollTopBtn />
    </Layout>
  );
}
