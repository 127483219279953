import React, {useState} from 'react';

import Img from "react-cool-img";

import TopArrow from "~/assets/images/common/top.svg";

export default function ScrollTopBtn() {

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true);
    }
    else if (scrolled <= 300){
      setVisible(false);
    }
  };

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <button className={`top-btn ${visible ? "visible" : "invisible"}`} onClick={scrollToTop}>
      <Img src={TopArrow} />
    </button>
  );
}
