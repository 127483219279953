import React from "react";

import { publicRoutes } from "./Routes";
import { useRoutes } from "react-router-dom";
import { useLocation } from "react-router";

import './App.css';

function App() {

  const routes = useRoutes(publicRoutes);

  // Scroll to the top at every page
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {routes}
    </>
  );
}

export default App;
