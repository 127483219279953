import React from "react";

import { Layout } from "~/components/layouts";
import { Breadcrumbs, ProjectHeader, ProjectTile, SkillWrapper, Skills, Overview, Tag, Text, ScrollTopBtn } from "~/components/elements";

import SafingLogin from "~/assets/images/works/apps/safing/safing-login.png";
import SafingDashboard from "~/assets/images/works/apps/safing/safing-dashboard.png";

export default function SafingApp() {
  return (
    <Layout>
      <Breadcrumbs
        works
        activeName="Web Apps"
      />

      <ProjectHeader
        title="Safing"
        date="2021 - Present"
        previousLink="/works/web-apps/neqto-app"
        nextLink="/works/web-apps/prime-app"
      />

      <div className="project-content">
        <ProjectTile image={SafingLogin} top>
          <SkillWrapper>
            <Skills title="Design">
              <Tag label="Figma" />
            </Skills>

            <Skills title="Development">
              <Tag label="React JS" />
              <Tag label="SCSS" />
              <Tag label="Tailwind CSS" />
              <Tag label="React Transition Group" />
              <Tag label="Toastify" />
            </Skills>
          </SkillWrapper>
          <Overview title="CSS Framework Implementation">
            <p className="paragraph">
              Safing was a new cloud security service in which I was tasked with researching and deciding on the best CSS Framework applicable before development began. I chose Tailwind CSS for its flexibility, lightweight size and utility based method. Components were mapped out, along with Tailwind configuration settings and the SCSS structure the styling was to be implemented.
            </p>
          </Overview>
        </ProjectTile>

        <div className="bg-blue">
          <ProjectTile image={SafingDashboard} safingDashboard>
            <Text title="React Components with Tailwind CSS">
              <p className="paragraph">
                The console design was given from an outsourcing company, which was later modified by another designer and I to further match Safing’s needs. Components were then developed and styled using React and Tailwind CSS. With more experience in React and component building with NEQTO and Prime Portal, I made the UI components functional with React states such as: accordions, dropdown menus and modals. Since Tailwind CSS itself has no pre-built components unlike Reactstrap, I implemented the state functionality. I also built a custom collapsible navigation, as well as a custom sidebar component as other libraries did not suffice for the provided UI design. Custom loading screens were also implemented with easily customizable loading components.
              </p>
              <p className="paragraph">
                React Transition Group was used to ensure smooth animations of interaction with the components. The application was also styled to be fully responsive.
              </p>
              <p className="paragraph">
                Toastify was used for toaster alerts. This library was chosen because of the customizability.
              </p>
              <p className="paragraph">
                After completion of the app, I was involved in helping refactor the code to ensure ease of maintenance in the future.
              </p>
            </Text>
          </ProjectTile>
        </div>

        <div className="divider"></div>
        <div className="project-link-wrapper">
          <a className="text-link" href="https://console.safing.com/login" target="_blank">
            See the Safing Console
          </a>
        </div>
        <div className="divider"></div>
      </div>

      <ScrollTopBtn />
    </Layout>
  );
}
