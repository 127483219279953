import React from "react";

import { Layout } from "~/components/layouts";
import { Breadcrumbs, ProjectHeader, ProjectTile, SkillWrapper, Skills, Overview, Tag, Text, ScrollTopBtn } from "~/components/elements";

import SunlifeHero from "~/assets/images/works/websites/sunlife/sunlife-hero.png";
import SunlifeCalculators from "~/assets/images/works/websites/sunlife/sunlife-calculators.png";

export default function SunlifeWebsite() {
  return (
    <Layout>
      <Breadcrumbs
        works
        activeName="Websites"
      />

      <ProjectHeader
        title="Sunlife Website"
        date="2018 - 2019"
        previousLink="/works/websites/samsung-website"
        nextLink="/works/websites/cibc-website"
      />

      <div className="project-content">
        <ProjectTile image={SunlifeHero} top>
          <SkillWrapper>
            <Skills title="Design">
              <Tag label="Adobe Illustrator" />
              <Tag label="Adobe Animate CC" />
            </Skills>

            <Skills title="Development">
              <Tag label="HTML5" />
              <Tag label="CSS" />
            </Skills>
          </SkillWrapper>
          <Overview title="Design and Development">
            <p className="paragraph">
              Our team was responsible for designing and developing financial calculators for many institutions, including Sunlife. A crucial significance was placed on accessibility and following W3C standards to ensure everyone is able to use the tools. The minimum requirement to meet was Web Content Accessibility Guidelines (WCAG) 2 Level AA Conformance. All designs were required to be fully responsive.
            </p>
          </Overview>
        </ProjectTile>

        <div className="bg-blue">
          <ProjectTile image={SunlifeCalculators}>
            <Text title="Branding and Multilingual">
              <p className="paragraph">
                It was important to follow the branding guidelines of the client company. If the tool was to be used in multiple regions, the design would have to accomodate for the varying lengths of different languages. Stock imagery also had to be chosen with localization in mind.
              </p>
            </Text>
          </ProjectTile>
        </div>

        <div className="divider"></div>
        <div className="project-link-wrapper">
          <a className="text-link" href="https://www.sunlife.ca/en/tools-and-resources/tools-and-calculators/" target="_blank">
            See the Sunlife Website
          </a>
        </div>
        <div className="divider"></div>
      </div>

      <ScrollTopBtn />
    </Layout>
  );
}
