

export default function Gradient({ animated, children }) {
  return (
    <div className={`gradient ${animated ? "animated" : ""}`}>
      <div className="landing-bg">
        <div className="home-bg">
          { children }
        </div>
      </div>
    </div>
  );
}
