import React from "react";
import { Link } from "react-router-dom";


export default function Landing() {
  return (
    <div className="gradient animated">
      <div className="landing-wrapper landing-bg">
        <div className="landing-text-wrapper">
          <h1>Hello!</h1>
          <p>Welcome to my portfolio.</p>

          <Link to={"/home"} className="primary-btn">
            Enter
          </Link>
        </div>
      </div>
    </div>
  );
}
