import { Link } from "react-router-dom";

export default function Layout({ whiteGradient, children }) {
  return (
    <div className="content-wrapper">
      <div className="header">
        <div className="container w-100">
          <Link className="logo" to={"/"}>
            <p className="name">Jen Ko</p>
            <span className="bar"></span>
            <p>Web Designer & Developer</p>
          </Link>
        </div>
      </div>

      <div className={`main-content ${whiteGradient ? "white-gradient" : ""}`}>
        { children }
      </div>

      <div className="container w-100">
        <div className="footer">
          &copy; {new Date().getFullYear()} Jennifer Ko. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}
