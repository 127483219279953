import React from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Img from "react-cool-img";

import { Gradient, Layout } from "~/components/layouts";
import { Breadcrumbs } from "~/components/elements";

import WebsitesImage from "~/assets/images/works/websites.svg";
import AppImage from "~/assets/images/works/apps.svg";

import NeqtoSite from "~/assets/images/works/websites/neqto.png";
import SafingSite from "~/assets/images/works/websites/safing.png";
import SamsungSite from "~/assets/images/works/websites/samsung.png";
import SunlifeSite from "~/assets/images/works/websites/sunlife.png";
import CIBCSite from "~/assets/images/works/websites/cibc.png";

import NeqtoApp from "~/assets/images/works/apps/neqto.svg";
import SafingApp from "~/assets/images/works/apps/safing.svg";
import PrimeApp from "~/assets/images/works/apps/prime.svg";

export default function Works() {
  return (
    <Gradient>
      <Layout>
        <Breadcrumbs
          activeName="Works"
        />
        <h1>Works</h1>

        <Tabs>
          <div className="container">
            <TabList className="tab-list">
              <Tab className="websites-tab" selectedClassName="active">
                <button>
                  <Img src={WebsitesImage} debounce="0" />
                  <p>Websites</p>
                </button>
              </Tab>
              <Tab className="apps-tab" selectedClassName="active">
                <button>
                  <Img src={AppImage} />
                  <p>Web Apps</p>
                </button>
              </Tab>
            </TabList>
          </div>
          <div className="divider"></div>
          <div className="container">
            <TabPanel className="websites-panel" selectedClassName="active">
              <Link className="selection" to={"/works/websites/neqto-website"}>
                <Img src={NeqtoSite} debounce="0" />
                <p>NEQTO</p>
              </Link>
              <Link className="selection" to={"/works/websites/safing-website"}>
                <Img src={SafingSite} debounce="0" />
                <p>Safing</p>
              </Link>
              <Link className="selection" to={"/works/websites/samsung-website"}>
                <Img src={SamsungSite} debounce="0" />
                <p>Samsung</p>
              </Link>
              <Link className="selection" to={"/works/websites/sunlife-website"}>
                <Img src={SunlifeSite} debounce="0" />
                <p>Sunlife</p>
              </Link>
              <Link className="selection" to={"/works/websites/cibc-website"}>
                <Img src={CIBCSite} debounce="0" />
                <p>CIBC</p>
              </Link>
            </TabPanel>
            <TabPanel className="apps-panel" selectedClassName="active">
              <Link className="selection" to={"/works/web-apps/neqto-app"}>
                <Img src={NeqtoApp} debounce="0" />
                <p>NEQTO</p>
              </Link>
              <Link className="selection" to={"/works/web-apps/safing-app"}>
                <Img src={SafingApp} debounce="0" />
                <p>Safing</p>
              </Link>
              <Link className="selection" to={"/works/web-apps/prime-app"}>
                <Img src={PrimeApp} debounce="0" />
                <p>Prime Portal</p>
              </Link>
            </TabPanel>
          </div>
        </Tabs>
      </Layout>
    </Gradient>
  );
}
