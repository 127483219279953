import Img from "react-cool-img";


export function ProjectTile({ image, top, safingDashboard, children }) {
  return (
    <div className={`project-grid container ${top ? "top" : ""} ${safingDashboard ? "safing" : ""}`}>
      <div className={top ? "hero" : "image-wrapper"}>
        <Img src={image} debounce="0" />
      </div>
      {children}
    </div>
  );
}

export function SkillWrapper({ children }) {
  return (
    <div className="skill-wrapper">
      <p className="tools">Tools Used</p>
      {children}
    </div>
  );
}

export function Skills({ title, children }) {
  return (<>
    <p className="subheading">{title}</p>
    <div className="tag-wrapper">
      {children}
    </div>
  </>);
}

export function Overview({ title, children }) {
  return (
    <div className="overview">
      <p className="subheading">{title}</p>
      {children}
    </div>
  );
}

export function Tag({ label }) {
  return (
    <p className="tag">{label}</p>
  );
}

export function Text({ title, children }) {
  return (
    <div className="text">
      <p className="subheading">{title}</p>
      {children}
    </div>
  );
}
