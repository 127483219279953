import React from "react";
import { Link } from "react-router-dom";

import { Gradient, Layout } from "~/components/layouts";
import { AboutIcon, WorksIcon, ArchivesIcon } from "~/components/elements";

export default function Home() {
  return (
    <Gradient animated>
      <Layout>
        <div className="home-wrapper">
          <div className="container w-100">
            <div className="home-icons-wrapper">
              <Link className="about-icon-wrapper" to={"/about"}>
                <AboutIcon />
              </Link>
              <Link className="works-icon-wrapper" to={"/works"}>
                <WorksIcon />
              </Link>
              <Link className="archives-icon-wrapper" to={"/archives"}>
                <ArchivesIcon />
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </Gradient>
  );
}
