import { Link } from "react-router-dom";

export default function Breadcrumbs({ works, activeName }) {
  return (
    <div className="breadcrumb-wrapper container">
      <p>
        <Link className="breadcrumb-link" to={"/home"}>Home</Link>
        {works ? <>
          <span className="slash">&frasl;</span>
          <Link className="breadcrumb-link" to={"/works"}>Works</Link>
          </>
        : ""}
        <span className="slash">&frasl;</span>
        <strong>{activeName}</strong>
      </p>
    </div>
  );
}
