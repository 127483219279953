import { Link } from "react-router-dom";

import Img from "react-cool-img";

import LeftArrow from "~/assets/images/common/left-arrow.svg";
import RightArrow from "~/assets/images/common/right-arrow.svg";

export default function ProjectHeader({ title, date, noPrevious, previousLink, previousLinkName, noNext, nextLink, nextLinkName }) {
  return (
    <div className="container">
      <div className="project-header">
        <div className="project-title">
          <h1>{ title }</h1>
          <p>{ date }</p>
        </div>
        <Link className={`text-link project-arrow ${noPrevious ? "d-none invisible" : ""} ${previousLinkName || ""}`} to={previousLink || "/" }>
          <Img className="arrow left" src={LeftArrow} />
          <p>Previous Project</p>
        </Link>
        <Link className={`text-link project-arrow ${noNext ? "d-none invisible" : ""} ${nextLinkName || ""}`} to={nextLink || "/"}>
          <p>Next Project</p>
          <Img className="arrow right" src={RightArrow} />
        </Link>
      </div>
    </div>
  );
}
