export default function AboutIcon() {
  return (
    <div className="about-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 466.48 269.61"
      >
        <defs>
          <linearGradient
            id="about-linear-gradient"
            x1="153.22"
            y1="47.84"
            x2="51.06"
            y2="224.78"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#5f85db" />
            <stop offset="0.49" stopColor="#f9bc60" />
            <stop offset={1} stopColor="#f792b4" />
          </linearGradient>
          <linearGradient
            id="about-linear-gradient-2"
            x1="442.98"
            y1="27.74"
            x2="395.97"
            y2="109.16"
            xlinkHref="#about-linear-gradient"
          />
          <linearGradient
            id="about-linear-gradient-3"
            x1="41.87"
            y1="164.83"
            x2="41.88"
            y2="164.83"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#b78c48" />
            <stop offset="0.02" stopColor="#b38b4b" />
            <stop offset="0.22" stopColor="#8e7c65" />
            <stop offset="0.42" stopColor="#717179" />
            <stop offset="0.62" stopColor="#5d6988" />
            <stop offset="0.81" stopColor="#506591" />
            <stop offset={1} stopColor="#4c6394" />
          </linearGradient>
          <linearGradient
            id="about-linear-gradient-4"
            x1="41.87"
            y1="204.44"
            x2="199.73"
            y2="204.44"
            xlinkHref="#about-linear-gradient-3"
          />
          <linearGradient
            id="about-linear-gradient-5"
            x1="274.43"
            y1="46.47"
            x2="432.08"
            y2="46.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#5f85db" />
            <stop offset="0.16" stopColor="#6385da" />
            <stop offset="0.32" stopColor="#7086d7" />
            <stop offset="0.49" stopColor="#8488d1" />
            <stop offset="0.65" stopColor="#a18bca" />
            <stop offset="0.82" stopColor="#c78ec0" />
            <stop offset="0.99" stopColor="#f492b5" />
            <stop offset={1} stopColor="#f792b4" />
          </linearGradient>
          <linearGradient
            id="about-linear-gradient-6"
            x1="41.87"
            y1="182.61"
            x2="199.73"
            y2="182.61"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#f9bc60" />
            <stop offset="0.02" stopColor="#f4ba64" />
            <stop offset="0.18" stopColor="#c7aa88" />
            <stop offset="0.35" stopColor="#a19da6" />
            <stop offset="0.52" stopColor="#8492bd" />
            <stop offset="0.68" stopColor="#708bce" />
            <stop offset="0.84" stopColor="#6386d8" />
            <stop offset={1} stopColor="#5f85db" />
          </linearGradient>
        </defs>
        <g>
          <g>
            <circle className="cls-1" cx="102.14" cy="136.31" r="102.14" />
            <circle className="cls-2" cx="419.48" cy="68.45" r={47} />
            <path
              className="cls-3"
              d="M414,108.24,346.75,69.41a44.71,44.71,0,0,0-44.82,0L279.52,82.35,100.25,185.86,77.68,199a14.9,14.9,0,0,0,0,25.81l67.2,38.81a45,45,0,0,0,45,0l22.46-13L391.56,147,414,134.1A15,15,0,0,0,414,108.24Z"
            />
            <path className="cls-4" d="M41.87,164.68v.3c0-.09,0-.19,0-.29Z" />
            <path
              className="cls-5"
              d="M174.78,222.45a50,50,0,0,1-49.95,0L50.15,179.33A16.42,16.42,0,0,1,41.87,165v14.76h0a16.42,16.42,0,0,0,8.28,14.35l74.68,43.12a50,50,0,0,0,50,0l24.95-14.49V208Z"
            />
            <polygon
              className="cls-6"
              points="199.73 207.96 199.73 222.34 398.92 107.33 398.92 92.95 199.73 207.96"
            />
            <path
              className="cls-7"
              d="M423.8,78.54,398.92,93v14.38L423.8,92.65a16.4,16.4,0,0,0,8.28-14.37h0V64.2A16.41,16.41,0,0,1,423.8,78.54Z"
            />
            <path className="cls-7" d="M419.62,71c0,.11,0,.23,0,.35V71Z" />
            <path
              className="cls-8"
              d="M274.43,21.07,299.32,6.69a49.68,49.68,0,0,1,49.8,0L423.8,49.83a16.6,16.6,0,0,1,0,28.73L398.92,93Z"
            />
            <path
              className="cls-9"
              d="M199.73,208l-24.95,14.49a50,50,0,0,1-49.95,0L50.15,179.33a16.57,16.57,0,0,1,0-28.69l25.09-14.56Z"
            />
            <path
              className="cls-6"
              d="M287.15,18.51a.7.7,0,0,0,.4.57L295,23.4a2.64,2.64,0,0,0,2.38-.1l.24-.14a1,1,0,0,0,.58-.76h0v.65a1,1,0,0,1-.58.75l-.24.14A2.67,2.67,0,0,1,295,24l-7.49-4.32a.7.7,0,0,1-.4-.61V18.5Z"
            />
            <path
              className="cls-10"
              d="M297.84,21.78l-7.49-4.32a2.7,2.7,0,0,0-2.39.1l-.24.14c-.7.41-.78,1-.17,1.38L295,23.4a2.64,2.64,0,0,0,2.38-.1l.24-.14C298.37,22.76,298.44,22.14,297.84,21.78Z"
            />
            <path
              className="cls-10"
              d="M287.55,21.78,295,17.46a2.67,2.67,0,0,1,2.38.1l.24.14c.71.41.78,1,.18,1.38l-7.49,4.32a2.66,2.66,0,0,1-2.39-.1l-.24-.14C287,22.76,286.94,22.14,287.55,21.78Z"
            />
            <path
              className="cls-6"
              d="M296,20.75l1.8-1a.72.72,0,0,0,.4-.61V18.5h0a.7.7,0,0,1-.4.57l-2.35,1.35Z"
            />
            <path
              className="cls-6"
              d="M292.69,22.05l-2.34,1.35a2.66,2.66,0,0,1-2.39-.1l-.24-.14a1,1,0,0,1-.57-.76h0v.65a1,1,0,0,0,.57.75l.24.14a2.7,2.7,0,0,0,2.39.1l2.89-1.67Z"
            />
            <path
              className="cls-6"
              d="M79.7,145.07a.71.71,0,0,1-.4.57L71.81,150a2.64,2.64,0,0,1-2.38-.1l-.25-.14a1,1,0,0,1-.57-.76h0v.64a1,1,0,0,0,.57.76l.25.13a2.61,2.61,0,0,0,2.38.1l7.49-4.32a.7.7,0,0,0,.4-.61v-.6Z"
            />
            <path
              className="cls-10"
              d="M69,148.34,76.5,144a2.64,2.64,0,0,1,2.38.1l.24.14c.71.41.79,1,.18,1.38L71.81,150a2.64,2.64,0,0,1-2.38-.1l-.25-.14C68.48,149.31,68.4,148.69,69,148.34Z"
            />
            <ellipse
              className="cls-10"
              cx="122.89"
              cy="187.78"
              rx="6.87"
              ry="3.89"
              transform="translate(-21.82 16.25) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M129.72,187.26c0,1.08-.82,2.22-2.36,3.11-2.85,1.64-7.17,1.82-9.65.4a2.92,2.92,0,0,1-1.66-2.33h0v1.73a2.78,2.78,0,0,0,1.65,2.59c2.48,1.43,6.8,1.25,9.65-.4,1.57-.9,2.37-2.06,2.36-3.16v-1.95Z"
            />
            <ellipse
              className="cls-10"
              cx="145.48"
              cy="203.44"
              rx="6.87"
              ry="3.89"
              transform="translate(-23.54 19.1) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M152.31,202.92c0,1.08-.81,2.22-2.35,3.11-2.86,1.65-7.18,1.83-9.65.4a2.92,2.92,0,0,1-1.67-2.32h0v1.72a2.82,2.82,0,0,0,1.66,2.6c2.47,1.42,6.79,1.24,9.65-.4,1.57-.91,2.36-2.07,2.36-3.17v-2Z"
            />
            <ellipse
              className="cls-10"
              cx="145.48"
              cy="187.78"
              rx="6.87"
              ry="3.89"
              transform="translate(-21.65 18.98) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M152.31,187.26c0,1.08-.81,2.22-2.35,3.11-2.86,1.64-7.18,1.82-9.65.4a2.94,2.94,0,0,1-1.67-2.33h0v1.73a2.8,2.8,0,0,0,1.66,2.59c2.47,1.43,6.79,1.25,9.65-.4,1.57-.9,2.36-2.06,2.36-3.16v-1.95Z"
            />
            <ellipse
              className="cls-10"
              cx="122.89"
              cy="203.44"
              rx="6.87"
              ry="3.89"
              transform="translate(-23.71 16.36) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M129.72,202.92c0,1.08-.82,2.22-2.36,3.11-2.85,1.65-7.17,1.83-9.65.4a2.91,2.91,0,0,1-1.66-2.32h0v1.72a2.81,2.81,0,0,0,1.65,2.6c2.48,1.42,6.8,1.24,9.65-.4,1.57-.91,2.37-2.07,2.36-3.17v-2Z"
            />
            <ellipse
              className="cls-10"
              cx="331.06"
              cy="23.11"
              rx="6.87"
              ry="3.89"
              transform="translate(-0.36 40.22) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M337.9,22.59c0,1.08-.82,2.22-2.36,3.1-2.85,1.65-7.17,1.83-9.65.4a2.87,2.87,0,0,1-1.66-2.32h0V25.5a2.77,2.77,0,0,0,1.65,2.59c2.48,1.43,6.8,1.25,9.65-.4,1.57-.9,2.37-2.06,2.36-3.16v-2Z"
            />
            <ellipse
              className="cls-10"
              cx="353.66"
              cy="38.77"
              rx="6.87"
              ry="3.89"
              transform="translate(-2.09 43.07) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M360.49,38.25c0,1.08-.81,2.22-2.35,3.11-2.86,1.65-7.18,1.83-9.65.4a2.92,2.92,0,0,1-1.67-2.32h0v1.72a2.82,2.82,0,0,0,1.66,2.6c2.47,1.42,6.79,1.24,9.65-.4,1.57-.91,2.36-2.07,2.35-3.17V38.24Z"
            />
            <ellipse
              className="cls-10"
              cx="353.66"
              cy="23.11"
              rx="6.87"
              ry="3.89"
              transform="translate(-0.2 42.96) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M360.49,22.59c0,1.08-.81,2.22-2.35,3.1-2.86,1.65-7.18,1.83-9.65.4a2.91,2.91,0,0,1-1.67-2.32h0V25.5a2.8,2.8,0,0,0,1.66,2.59c2.47,1.43,6.79,1.25,9.65-.4,1.57-.9,2.36-2.06,2.35-3.16v-2Z"
            />
            <ellipse
              className="cls-10"
              cx="331.06"
              cy="38.77"
              rx="6.87"
              ry="3.89"
              transform="translate(-2.26 40.34) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M337.9,38.25c0,1.08-.82,2.22-2.36,3.11-2.85,1.65-7.17,1.83-9.65.4a2.89,2.89,0,0,1-1.66-2.32h0v1.72a2.79,2.79,0,0,0,1.65,2.6c2.48,1.42,6.8,1.24,9.65-.4,1.57-.91,2.37-2.07,2.36-3.17V38.24Z"
            />
            <ellipse
              className="cls-11"
              cx="385.43"
              cy="63.55"
              rx="11.81"
              ry="6.68"
              transform="translate(-4.86 47.1) rotate(-6.95)"
            />
            <ellipse
              className="cls-12"
              cx="385.43"
              cy="63.55"
              rx="10.36"
              ry="5.86"
              transform="translate(-4.86 47.1) rotate(-6.95)"
            />
            <ellipse
              className="cls-10"
              cx="385.43"
              cy="61.26"
              rx="10.01"
              ry="5.66"
              transform="translate(-4.58 47.08) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M392,65c-4.16,2.41-10.45,2.67-14.05.59a4.27,4.27,0,0,1-2.42-3.3v.78c-.11,1.41.68,2.77,2.41,3.78,3.6,2.08,9.89,1.81,14.05-.59,2.29-1.32,3.45-3,3.44-4.61V60.33C395.44,62,394.28,63.68,392,65Z"
            />
            <ellipse
              className="cls-10"
              cx="385.43"
              cy="55.28"
              rx="13.55"
              ry="7.67"
              transform="translate(-3.86 47.04) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M394.26,60.38c-5.63,3.25-14.15,3.6-19,.79-2.08-1.2-3.15-2.79-3.27-4.46v.9c-.14,1.91.92,3.76,3.27,5.11,4.87,2.82,13.39,2.46,19-.79,3.09-1.78,4.66-4.07,4.65-6.24V54C399,56.23,397.4,58.57,394.26,60.38Z"
            />
            <ellipse
              className="cls-13"
              cx="385.43"
              cy="55.28"
              rx="10.91"
              ry="6.17"
              transform="translate(-3.86 47.04) rotate(-6.95)"
            />
            <ellipse
              className="cls-11"
              cx="89.66"
              cy="176.65"
              rx="11.81"
              ry="6.68"
              transform="translate(-20.71 12.15) rotate(-6.95)"
            />
            <ellipse
              className="cls-12"
              cx="89.66"
              cy="176.65"
              rx="10.36"
              ry="5.86"
              transform="translate(-20.71 12.15) rotate(-6.95)"
            />
            <ellipse
              className="cls-10"
              cx="89.66"
              cy="174.36"
              rx="10.01"
              ry="5.66"
              transform="translate(-20.44 12.13) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M96.18,178.13c-4.16,2.4-10.45,2.66-14.05.58a4.27,4.27,0,0,1-2.42-3.3v.78c-.11,1.41.68,2.78,2.41,3.78,3.6,2.08,9.89,1.82,14.05-.59,2.29-1.32,3.45-3,3.44-4.61v-1.34C99.67,175.06,98.51,176.78,96.18,178.13Z"
            />
            <ellipse
              className="cls-10"
              cx="89.66"
              cy="168.38"
              rx="13.55"
              ry="7.67"
              transform="translate(-19.71 12.08) rotate(-6.95)"
            />
            <path
              className="cls-6"
              d="M98.49,173.48c-5.63,3.25-14.15,3.6-19,.79-2.08-1.2-3.15-2.79-3.27-4.46v.91c-.14,1.9.92,3.75,3.27,5.1,4.87,2.82,13.39,2.46,19-.79,3.09-1.78,4.66-4.07,4.65-6.24v-1.65C103.2,169.34,101.63,171.67,98.49,173.48Z"
            />
            <ellipse
              className="cls-13"
              cx="89.66"
              cy="168.38"
              rx="10.91"
              ry="6.17"
              transform="matrix(0.99, -0.12, 0.12, 0.99, -19.71, 12.08)"
            />
            <polygon
              className="cls-10"
              points="75.24 136.08 199.73 207.96 398.92 92.95 274.43 21.07 75.24 136.08"
            />
            <polygon
              className="cls-6"
              points="89.66 136.08 199.73 200.77 388.25 92.95 274.43 29.39 89.66 136.08"
            />
            <polygon
              className="cls-14"
              points="102.11 136.08 199.73 193.58 375.99 92.95 274.43 36.47 102.11 136.08"
            />
            <path
              className="cls-15"
              d="M197.47,144.06l-5.59-1.87L186,145.57l3.25,3.22-3.67,2.12-13-14,4.16-2.4L201.13,142Zm-9.7-3.31-5.48-1.81-1.41-.48-1.74-.58c-.59-.2-1.09-.38-1.51-.54.27.24.6.55,1,.92s.75.71,1.1,1,.58.57.71.72l3.17,3.15Z"
            />
            <path
              className="cls-15"
              d="M186.5,127.6l4.59,2.65c.54.31,1.06.63,1.54.94l1.14.76.13-.08a2.29,2.29,0,0,1-.17-1.62,3,3,0,0,1,1.55-1.6,8.45,8.45,0,0,1,5.21-.87,17.34,17.34,0,0,1,6.76,2.42q3.63,2.1,4.2,3.93c.38,1.23-.14,2.24-1.55,3.06a6.54,6.54,0,0,1-2.69.9,12.6,12.6,0,0,1-2.53,0l-.22.13,1,1.22-2.56,1.48-19.75-11.4Zm10.79,3.45c-.86.51-1.16,1-.89,1.62a5.67,5.67,0,0,0,2.28,2l.42.24a12.45,12.45,0,0,0,3.68,1.52,4.19,4.19,0,0,0,3-.47,1.09,1.09,0,0,0,.54-1.59,6.45,6.45,0,0,0-2.58-2.19,10.92,10.92,0,0,0-3.75-1.45A4.24,4.24,0,0,0,197.29,131.05Z"
            />
            <path
              className="cls-15"
              d="M221.17,122.16q3.54,2,3.85,4.08t-2.45,3.63a10.41,10.41,0,0,1-3.91,1.27,14,14,0,0,1-4.61-.23,16.33,16.33,0,0,1-4.78-1.88c-2.36-1.36-3.63-2.71-3.83-4.06s.63-2.55,2.49-3.63a10.35,10.35,0,0,1,3.92-1.27,13.79,13.79,0,0,1,4.58.24A15.91,15.91,0,0,1,221.17,122.16Zm-8.48,4.9a11.6,11.6,0,0,0,3.78,1.49,4.43,4.43,0,0,0,3-.5c.89-.51,1.17-1.09.85-1.74a6.51,6.51,0,0,0-2.59-2.18,11.37,11.37,0,0,0-3.75-1.48,4.46,4.46,0,0,0-3,.52c-.88.51-1.17,1.08-.87,1.72A6.36,6.36,0,0,0,212.69,127.06Z"
            />
            <path
              className="cls-15"
              d="M228.15,110l14.18,8.19-2.56,1.48-2.27-.79-.18.11a1.74,1.74,0,0,1,0,1.84,4.57,4.57,0,0,1-1.67,1.54,8.57,8.57,0,0,1-4.41,1.12,10.65,10.65,0,0,1-5.16-1.6l-9.24-5.34,3.35-1.94,8.29,4.79a7.68,7.68,0,0,0,2.77,1,3.48,3.48,0,0,0,2.27-.42c1-.59,1.32-1.23.89-1.91a8.57,8.57,0,0,0-2.91-2.33l-6.68-3.85Z"
            />
            <path
              className="cls-15"
              d="M248.18,111.51a4.91,4.91,0,0,0,1-.69,5.82,5.82,0,0,0,.71-.78l2.88,1.66a4,4,0,0,1-.87,1,9,9,0,0,1-1.56,1.12,7.9,7.9,0,0,1-2.39.89,7,7,0,0,1-2.8,0,11,11,0,0,1-3.36-1.36l-6.83-3.94-1.6.92-1.64-.94.55-1.82-2-2.28,2.13-1.24,3,1.74,3.44-2,2.91,1.68-3.44,2,6.83,4a3.21,3.21,0,0,0,1.61.46A2.86,2.86,0,0,0,248.18,111.51Z"
            />
            <path
              className="cls-15"
              d="M268.52,103,250.1,96.88l-.09.05,1.63.86c.72.38,1.5.79,2.32,1.24s1.55.84,2.18,1.2l8.62,5-3,1.76L243.18,96.26l4.63-2.68,18,6,.07,0L255.71,89l4.63-2.68,18.55,10.71-3.17,1.83-8.78-5.06c-.59-.34-1.25-.74-2-1.19l-2.11-1.3c-.66-.4-1.15-.71-1.48-.92l-.09,0,10.38,10.78Z"
            />
            <path
              className="cls-15"
              d="M273.29,83.61a10.48,10.48,0,0,1,5.7-1.36,13.32,13.32,0,0,1,6.26,1.9l1.87,1.08L279.2,89.8a8,8,0,0,0,3.41,1,5.7,5.7,0,0,0,3.15-.74,8.75,8.75,0,0,0,1.88-1.4A6.49,6.49,0,0,0,288.81,87l3,1.72a5.38,5.38,0,0,1-1.15,1.55,10.66,10.66,0,0,1-2.16,1.53,11.85,11.85,0,0,1-4.05,1.43,13.1,13.1,0,0,1-4.62-.1,15.48,15.48,0,0,1-4.88-1.88,10.27,10.27,0,0,1-3.4-2.8,2.78,2.78,0,0,1-.46-2.62A4.8,4.8,0,0,1,273.29,83.61Zm2.78,1.58q-1,.57-.9,1.35c.07.52.58,1.1,1.52,1.73l4.7-2.71a7.26,7.26,0,0,0-2.72-.89A4.12,4.12,0,0,0,276.07,85.19Z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
