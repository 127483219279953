import React from "react";

import { Layout } from "~/components/layouts";
import { Breadcrumbs, ProjectHeader, ProjectTile, SkillWrapper, Skills, Overview, Tag, Text, ScrollTopBtn } from "~/components/elements";

import PrimeLogin from "~/assets/images/works/apps/prime/prime-login.png";

export default function PrimeApp() {
  return (
    <Layout>
      <Breadcrumbs
        works
        activeName="Web Apps"
      />

      <ProjectHeader
        title="Prime Portal"
        date="2021 - Present"
        previousLink="/works/web-apps/safing-app"
        noNext
      />

      <div className="project-content">
        <ProjectTile image={PrimeLogin} top>
          <SkillWrapper>
            <Skills title="Design">
              <Tag label="Figma" />
            </Skills>

            <Skills title="Development">
              <Tag label="React JS" />
              <Tag label="SCSS" />
              <Tag label="Tailwind CSS" />
              <Tag label="React Transition Group" />
            </Skills>
          </SkillWrapper>
          <Overview title="CSS Framework Implementation">
            <p className="paragraph">
              Prime Portal was a smaller scale app, a new service to be provided in which I was tasked to decide on the CSS Framework before development began. After extensive research, I chose Tailwind CSS because of the customization available and lightweight size. This framework was later on also used for the Safing project because of the positive experience using it with this project.
            </p>

            <p className="subheading mt-2">React Components with Tailwind CSS</p>
            <p className="paragraph">
              After reviewing the console design provided by an outsourcing company, I helped map out the necessary components and led the appropriate SCSS structure in a two man team. Together, we developed all the UI components, I was responsible for creating all the components with functional parts that required state management, such as: modals, accordions and dropdown menus. Later on, using variables and Tailwind features, I created a dark theme for the application as well.
            </p>
            <p className="paragraph">
              *Unfortunately, a preview of this application is not available for public view.
            </p>
          </Overview>
        </ProjectTile>

        <div className="divider mt-5"></div>
        <div className="project-link-wrapper">
          <a className="text-link" href="https://prime.jig-saw.com/login" target="_blank">
            See the Prime Portal Console
          </a>
        </div>
        <div className="divider"></div>
      </div>

      <ScrollTopBtn />
    </Layout>
  );
}
